/**
 * location接口统一管理
 */
import { get } from './http'

/**
 * 获取省
 */
export const getprovincelist = () => get('location/area/province', {})

/**
 * 获取市
 * @param province
 */
export const getcitylist = (province) => get('location/area/' + province + '/city', {})

/**
 * 获取区
 * @param city
 */
export const getdistrictlist = (city) => get('location/area/' + city + '/district', {})
