<template>
  <div class="imageView">
    <div class="imageView-big">
      <img :src="data[currentIndex].url"
           v-if="data.length>0">
      <img src="../../assets/common/img_empty.png"
           v-else>
    </div>
    <p v-if="!disabled">选择图片设为主图</p>
    <div class="imageView-list">
      <ImgUpload :uploadpath="uploadpath"
                 @uploadSuccess="uploadSuccess"
                 v-if="count&&!disabled">
        <div class="imageView-item imageView-add">
          <Icon custom="i-icon icon-plus"
                size="64"></Icon>
        </div>
      </ImgUpload>
      <template v-for="(item,index) in data">
        <div class="imageView-item"
             :key="index"
             @click.stop="onClickImageItem(index)">
          <img :src="item.url">
          <Icon custom="i-icon icon-delete_pic"
                size="24"
                color="#0652DD"
                class="imageView-del"
                @click.stop="onDeleteImage(item,index)"
                v-if="!disabled"></Icon>
          <Radio :value="item.isDefault"
                 @on-change="onChangeRadio(item,$event)"
                 v-if="!disabled || item.isDefault"
                 class="imageView-radio"></Radio>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ImgUpload from '../../components/common/ImgUpload';
export default {
  components: { ImgUpload },
  props: {
    data: Array,
    count: {
      type: Number,
      default: -1
    },
    uploadpath: {
      type: String,
      default: 'test/'
    },
    disabled: Boolean
  },
  data () {
    return {
      currentIndex: 0
    }
  },
  watch: {
    data (newVal) {
      if (newVal.length < this.currentIndex + 1) {
        if (newVal.length === 0) {
          this.currentIndex = 0
          this.$emit('on-click-item', this.currentIndex)
        } else {
          this.currentIndex = newVal.length - 1
          this.$emit('on-click-item', this.currentIndex)
        }
      }
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onClickImageItem (index) {
      this.currentIndex = index
      this.$emit('on-click-item', index)
    },
    onDeleteImage (item, index) {
      this.data.splice(index, 1)
      if (item.isDefault && this.data.length > 0) {
        this.data[0].isDefault = true
      }
    },
    onChangeRadio (item) {
      this.data.forEach(items => {
        items.isDefault = false
      });
      item.isDefault = true;
    },
    uploadSuccess (arr) {
      this.$emit('upload-success', arr)
    }
  },
}
</script>

<style lang='scss' scoped>
.imageView {
  padding-bottom: 30px;
  .imageView-big {
    width: 480px;
    height: 360px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-size: 14px;
    margin-top: 20px;
  }
  .imageView-list {
    width: 480px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .imageView-item {
      width: 148px;
      height: 111px;
      margin-bottom: 30px;
      position: relative;
      &:not(:nth-child(3n)) {
        margin-right: 16px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .imageView-del {
        position: absolute;
        top: -12px;
        right: -12px;
        cursor: pointer;
      }
      .imageView-radio {
        position: absolute;
        left: 10px;
        bottom: 10px;
      }
    }
    .imageView-add {
      border: 4px solid #cedefa;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
</style>