<template>
  <div class="flex">
    <Select v-model="addressinfo.province"
            placeholder="省/市/区"
            label-in-value
            :style="{width:width+'px'}"
            style="margin-right: 10px">
      <Option :key="item.name"
              :value="item.code"
              :label="item.name"
              v-for="item in provincelist">{{item.name }}</Option>
    </Select>
    <Select v-model="addressinfo.city"
            placeholder="市/区/盟"
            label-in-value
            :style="{width:width+'px'}"
            style="margin-right: 10px">
      <Option :key="item.name"
              :value="item.code"
              :label="item.name"
              v-for="item in citylist">{{ item.name }}</Option>
    </Select>
    <Select v-model="addressinfo.area"
            placeholder="县/市/区/旗"
            label-in-value
            :style="{width:width+'px'}"
            v-if="!noarea">
      <Option :key="item.name"
              :value="item.code"
              :label="item.name"
              v-for="item in arealist">{{ item.name }}</Option>
    </Select>
  </div>
</template>

<script>
import { getprovincelist, getcitylist, getdistrictlist } from '../../services/locationapi';

export default {
  name: 'AddressChooseComponent',
  props: {
    width: {
      type: Number,
      default: 120
    },
    addressinfo: Object,
    noarea: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      provincelist: [],                   //第一阶省
      citylist: [],                       //第二阶市
      arealist: [],                       //第三阶区
      status: false
    }
  },
  watch: {
    'addressinfo.initialization': {
      handler (newValue) {
        if (newValue) {
          this.dataformat()
        } else {
          this.status = false
        }
      }
    },
    'addressinfo.province': {
      handler (newValue) {
        if (this.status) {
          return
        }
        if (newValue && parseInt(newValue) > 0) {
          getcitylist(newValue).then(res => {
            if (res.code === 0) {
              this.citylist = res.data || []
              this.addressinfo.city = ''
            }
          })
          this.addressinfo.provName = ''
          let resultlist = this.provincelist.filter(child => {
            return parseInt(newValue) === parseInt(child.code)
          })
          if (resultlist && resultlist.length) {
            this.addressinfo.provName = resultlist[0].name
          }
        } else {
          this.citylist = []
          this.addressinfo.city = ''
          this.addressinfo.provName = ''
        }
      }
    },
    'addressinfo.city': {
      handler (newValue) {
        if (this.status) {
          return
        }
        if (newValue && parseInt(newValue) > 0) {
          getdistrictlist(newValue).then(res => {
            if (res.code === 0) {
              this.arealist = res.data || []
              this.addressinfo.area = ''
            }
          })
          this.addressinfo.cityName = ''
          let resultlist = this.citylist.filter(child => {
            return parseInt(newValue) === parseInt(child.code)
          })
          if (resultlist && resultlist.length) {
            this.addressinfo.cityName = resultlist[0].name
          }
        } else {
          this.arealist = []
          this.addressinfo.area = ''
          this.addressinfo.cityName = ''
        }
      }
    },
    'addressinfo.area': {
      handler (newValue) {
        if (this.status) {
          return
        }
        if (newValue && parseInt(newValue) > 0) {
          this.addressinfo.areaName = ''
          let resultlist = this.arealist.filter(child => {
            return parseInt(newValue) === parseInt(child.code)
          })
          if (resultlist && resultlist.length) {
            this.addressinfo.areaName = resultlist[0].name
          }
        } else {
          this.addressinfo.areaName = ''
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getprovincelist()
      this.dataformat()
    },
    async dataformat () {
      this.status = true
      if (this.addressinfo.province && parseInt(this.addressinfo.province) > 0) {
        let res = await getcitylist(this.addressinfo.province)
        if (res.code === 0) {
          this.citylist = res.data.map(child => {
            child.code = child.code + ''
            return child
          }) || []
          if (this.addressinfo.city && parseInt(this.addressinfo.city) > 0) {
            let cchild = await getdistrictlist(this.addressinfo.city)
            if (cchild.code === 0) {
              this.arealist = cchild.data.map(child => {
                child.code = child.code + ''
                return child
              }) || []
            }
          }
        }
      }
      this.status = false
    },
    getprovincelist () {
      getprovincelist().then(res => {
        if (res.code === 0) {
          this.provincelist = res.data || []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
}
</style>
