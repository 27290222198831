<template>
  <div>
    <Modal :value="visible"
           :title="manageId?'编辑':'新增'+'库管'"
           footer-hide
           @on-visible-change="onModalClose"
           width="560">
      <div class="createStorekeeper">
        <ul class="createStorekeeper-form">
          <li class="createStorekeeper-li">
            <p><span style="color:#D0021B">*</span>姓名:</p>
            <Input v-model="name"
                   placeholder="姓名"
                   maxlength="20"
                   style="width:220px" />
          </li>
          <li class="createStorekeeper-li">
            <p><span style="color:#D0021B">*</span>电话:</p>
            <Input v-model="phone"
                   placeholder="电话"
                   maxlength="11"
                   @on-keyup="phone=formatNumber(phone)"
                   style="width:220px" />
          </li>
        </ul>
        <div class="createStorekeeper-btn">
          <Button type="primary"
                  class="m-r-20"
                  @click.stop="onClickConfirm">确认</Button>
          <Button @click.stop="onClickCancle">取消</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createStorekeeper, editStorekeeper, getStorekeeperDetail } from '../../services/storekeeperapi';
import AddressChooseComponent from '../common/AddressChooseComponent';
import ImageListComponent from '../common/ImageListComponent';
export default {
  components: { AddressChooseComponent, ImageListComponent },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    manageId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$emit('update:manageId', 0)
        this.resetStorageInfo()
      }
    },
    manageId (newVal) {
      if (newVal && this.visible) {
        this.getStorekeeperDetail()
      } else {
        this.$emit('update:manageId', 0)
        this.resetStorageInfo()
      }
    }
  },
  data () {
    return {
      name: '',
      phone: ''
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 只能输入数字
    formatNumber (str) {
      return str.replace(/[^\d]/g, '')
    },
    onModalClose (status) {
      if (!status) {
        this.resetStorageInfo()
        this.$emit('change', false)
      }
    },
    // 重置库管信息
    resetStorageInfo () {
      this.name = ''
      this.phone = ''
    },
    // 点击确定
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请填写姓名')
      if (!this.phone) return this.$Message.info('请填写电话')
      let params = {
        manageName: this.name,
        managePhone: this.phone
      }
      if (this.manageId) {
        editStorekeeper(this.manageId, params).then(res => {
          if (res.code === 0) {
            this.$Message.info('修改成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        createStorekeeper(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('新建成功')
            this.$emit('on-click-confirm')
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
    },
    // 获取库管详情
    getStorekeeperDetail () {
      getStorekeeperDetail(this.manageId).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.name = data.manageName
          this.phone = data.managePhone
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.createStorekeeper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .createStorekeeper-form {
    position: relative;
    .createStorekeeper-li {
      display: flex;
      margin-top: 30px;
      p {
        width: 80px;
        font-size: 16px;
        line-height: 32px;
        text-align: right;
        margin-right: 10px;
      }
    }
    .createStorekeeper-pic {
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      p {
        text-align: left;
      }
      .createStorekeeper-pic-upload {
        width: 160px;
        height: 120px;
        border: 1px dashed rgba(0, 0, 0, 0.24);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #999;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .createStorekeeper-btn {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
}
.uploadPic {
  padding-left: 20px;
  .uploadPic-title {
    font-size: 16px;
  }
  .uploadPic-btn {
    display: flex;
    justify-content: center;
  }
}
</style>