import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 获取库管列表
 */
export const getStorekeeperList = (params) => get('/tradeapp/trace/storage/manage/ent/list', params)

/**
 * 获取库管详情
 */
export const getStorekeeperDetail = (id) => get('/tradeapp/trace/storage/manage/' + id)

/**
 * 批量删除库管
 */
export const deleteStorekeeper = (params) => del('/tradeapp/trace/storage/manage', params)

/**
 * 新建库管
 */
export const createStorekeeper = (params) => post('/tradeapp/trace/storage/manage', params)

/**
 * 修改库管
 */
export const editStorekeeper = (id, params) => put('/tradeapp/trace/storage/manage/' + id, params)